import { assetActions } from '@bvt-features/pertamina/store/asset/asset.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailAsset,
  getListAsset,
  doDownloadAssetList,
  createUpdateAsset,
  doDeleteAsset,
  getRukoListDetail,
  getTenantListDetail,
  getPOIListDetail,
  getStatusAnalysisAsset,
} from '@bvt-features/pertamina/store/asset/asset.asyncAction';

export const usePertaminaAsset = () => {
  const state = useSelector((state) => state.features.PERTAMINA.asset);
  const dispatch = useDispatch();

  const resetState = () => dispatch(assetActions.resetState());

  /**
   *
   * @param {object} param
   * @param {'status_LIST_ASSET'|'status_DASHBOARD_RESULT'} param.type
   */
  const resetStatus = (param) =>
    dispatch(assetActions.resetStatus({ type: param.type }));

  const resetDashboard = () => dispatch(assetActions.resetDashboard());

  /**
   *
   * @param {object} param
   * @param {string} param.id
   */
  const setSelectedID = (param) => dispatch(assetActions.setSelectedID(param));

  /**
   *
   * @param {object} param
   * @param {string} param.img
   */
  const setSelectedImg = (param) =>
    dispatch(assetActions.setSelectedImg(param));

  /**
   *
   * @param {object} param
   * @param {object} param.coordinate
   * @param {string} param.coordinate.lat
   * @param {string} param.coordinate.lng
   */
  const setCoordinate = (param) => dispatch(assetActions.setCoordinate(param));

  const getListAssetPertamina = (param) => dispatch(getListAsset(param));

  const getDetailAssetPertamina = (param) => dispatch(getDetailAsset(param));

  const doDownloadAssetListPertamina = (param) =>
    dispatch(doDownloadAssetList(param));

  /**
   *
   * @param {boolean} param
   */
  const setStartDownload = (param) => {
    dispatch(assetActions.setStartDownload(param));
  };

  /**
   * @param {boolean} param
   */
  const setToggleStatus = (param) => dispatch(assetActions.setToggleStatus(param));

  const resetDownload = () => dispatch(assetActions.resetDownload());

  /**
   * @param {object} params
   * @param {string} params.id
   * @param {string} params.lat
   * @param {string} params.long
   * @param {string} params.name
   * @param {string} params.address
   */
  const setEditData = (params) => dispatch(assetActions.setEditData(params));

  /**
   *
   * @param {object} param
   * @param {string} param.site_id
   * @param {string} param.name
   * @param {string} param.longitude
   * @param {string} param.latitude
   * @param {string} param.address
   */
  const addEditAsset = (param) => dispatch(createUpdateAsset(param));

  /**
   * @param {string} id
   */
  const deleteAsset = (id) => dispatch(doDeleteAsset(id));

  /**
   * @param {string} id
   */
  const getRukoList = (id) => dispatch(getRukoListDetail(id));

  /**
   * @param {string} id
   */
  const getTenantList = (id) => dispatch(getTenantListDetail(id));

  /**
   * @param {string} id
   */
  const getPOIList = (id) => dispatch(getPOIListDetail(id));

  const getStatusAnalysis = (param) =>
    dispatch(getStatusAnalysisAsset(param));

  return {
    getStatusAnalysis,
    getPOIList,
    getTenantList,
    getRukoList,
    setEditData,
    deleteAsset,
    resetDownload,
    setStartDownload,
    doDownloadAssetListPertamina,
    getListAssetPertamina,
    resetState,
    resetDashboard,
    resetStatus,
    getDetailAssetPertamina,
    setSelectedID,
    setSelectedImg,
    setCoordinate,
    addEditAsset,
    setToggleStatus,
    state,
  };
};
