import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import 'leaflet.markercluster';
import 'leaflet.markercluster.placementstrategies';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import PertaminaMarker from '@bvt-features/pertamina/assets/marker.png';
import PertaminaNewMarker from '@bvt-features/pertamina/assets/new-marker.png';
import { MarkerPopup } from '../MarkerPopup';
import { getIcon } from '@bvt-shared/utils/iconUtils';

export default function MarkerCluster(props) {
  const map = useMap();
  const popRef = useRef(null);
  const [popupPosition, setPopupPosition] = useState({ lat: null, lng: null, properties: {} });

  useEffect(() => {
    let markerClusterGroupInstance = new L.MarkerClusterGroup({
      animate: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      maxClusterRadius: 32,
      elementsPlacementStrategy: 'concentric',
      elementsMultiplier: 1,
      spiderfyDistanceMultiplier: 1.2,
      firstCircleElements: 5,
      animateAddingMarkers: false,
      iconCreateFunction: (icfParam) => {
        const multiplier = map.getZoom() > 16 ? 8 : 3;
        const totalMarkerPerCluster = icfParam.getChildCount();
        const percentageOfTotalMarker =
          (totalMarkerPerCluster / props.data.length) * 100;
        const opacityMultiplier = (percentageOfTotalMarker / 10) * multiplier;
        const sizeMultiplier = opacityMultiplier > 0.9 ? 1.3 : 0.8;

        const baseStyle = {
          width: 52 * sizeMultiplier,
          height: 52 * sizeMultiplier,
          borderRadius: 9999,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: `rgba(229,73,52,1)`,
          opacity: props.opacity,
        };
        const htmlElm = renderToStaticMarkup(
          <div
            style={{
              ...baseStyle,
              width: 42 * sizeMultiplier,
              height: 42 * sizeMultiplier,
              background: `rgba(229,73,52,0.5)`,
            }}
          >
            <div
              style={{
                ...baseStyle,
                width: 32 * sizeMultiplier,
                height: 32 * sizeMultiplier,
              }}
            >
              <div
                style={{ color: '#fff', fontSize: '1rem', fontWeight: 600 }}
              >
                {totalMarkerPerCluster}
              </div>
            </div>
          </div>
        );

        return L.divIcon({ html: htmlElm, iconAnchor: { x: 22, y: 22 } });
      },
    });

    markerClusterGroupInstance.setZIndex(101);

    if (props?.data?.length > 0) {
      // Add Cluster To Map
      props.data?.forEach((el) => {
        const pertaminaMarker = el?.is_preanalytic ? PertaminaMarker : PertaminaNewMarker;
        // Define New instance of marker
        const markerLayer = L.marker([el.latitude, el.longitude], {
          icon: L.icon({
            iconUrl: el.icon ? el.icon : pertaminaMarker,
            iconSize: [36, 42],
            iconAnchor: [18, 40],
          }),
        });

        markerLayer.on('mouseover', (e) => {
          if (
            popupPosition.lat !== e.latlng.lat ||
            popupPosition.lng !== e.latlng.lng
          ) {
            if (!el?.type) { // pertamina marker
              setPopupPosition({
                lat: e.latlng.lat,
                lng: e.latlng.lng,
                properties: {
                  ...el,
                  image: getIcon('pertamina', el.image?.includes('src/pertamina') ? el?.image : ''),
                },
              });
            } else {
              setPopupPosition({
                lat: e.latlng.lat,
                lng: e.latlng.lng,
                properties: {
                  ...el.properties,
                  type: el?.type,
                }
              });
            }
          }
        });

        markerLayer.on('mouseout', () => {
          setPopupPosition({ lat: null, lng: null });
        });

        markerLayer.on('click', () => {
          setPopupPosition({ lat: null, lng: null });
          props?.onClickMarker?.(el.id, getIcon('pertamina', el.image?.includes('src/pertamina') ? el?.image : ''));
        });

        // Add Marker To cluster Layer
        markerClusterGroupInstance.addLayer(markerLayer);
      });

      markerClusterGroupInstance.addTo(map);
    }
    return () => {
      // Remove Cluster On Remount
      markerClusterGroupInstance.removeFrom(map);
    };
  }, [props.data, props.opacity]);

  return (
    <MarkerPopup
      lat={popupPosition.lat}
      lng={popupPosition.lng}
      properties={popupPosition.properties}
      ref={popRef}
    />
  );
}

MarkerCluster.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.arrayOf(PropTypes.number),
      properties: PropTypes.object
    })
  ),
};
