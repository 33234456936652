import React, { useState } from 'react';
import './AssetItem.scss';
import NoPhoto from '@bvt-features/pertamina/assets/nophoto.svg';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Button } from 'antd';
import ReactDOM from 'react-dom';
import Popup from '../../components/Popup';

/**
 * @param {object} props
 * @param {object} props.data
 * @param {Function} props.onClick
 * @param {'horizontal' | 'vertical'} props.type
 * @returns
 */

const PencilIcon = () => (
  <svg
    fill='none'
    height={16}
    viewBox='0 0 16 16'
    width={16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_9752_16068)'>
      <path
        d='M7.1228 14.4144L14.4231 7.11321C14.8637 6.67225 15.1111 6.07441 15.1111 5.45107C15.1111 4.82774 14.8637 4.2299 14.4231 3.78893L12.2024 1.57695C11.7615 1.13639 11.1637 0.888916 10.5404 0.888916C9.91717 0.888916 9.3194 1.13639 8.87848 1.57695L1.57815 8.87811C1.13708 9.31883 0.889074 9.9167 0.888672 10.5403V15.1111H5.45994C6.08343 15.1107 6.68123 14.8627 7.1219 14.4216L7.1228 14.4144ZM2.41904 10.4952C2.41983 10.2895 2.5014 10.0924 2.64616 9.94624L9.9519 2.63968C10.0988 2.49381 10.2974 2.41195 10.5044 2.41195C10.7114 2.41195 10.91 2.49381 11.0569 2.63968L13.2758 4.85797C13.4217 5.00487 13.5035 5.20349 13.5035 5.41051C13.5035 5.61753 13.4217 5.81615 13.2758 5.96306L6.00972 13.3093C5.86341 13.4543 5.66592 13.5359 5.45994 13.5364H2.45869L2.41904 10.4952Z'
        fill='#6C757D'
      />
    </g>
    <defs>
      <clipPath id='clip0_9752_16068'>
        <rect fill='white' height='16' width='16' />
      </clipPath>
    </defs>
  </svg>
);

const TrashIcon = () => (
  <svg
    fill='none'
    height={16}
    viewBox='0 0 16 16'
    width={16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      clipRule='evenodd'
      d='M5.58933 11.4521C5.67914 11.5712 5.81114 11.651 5.95801 11.6752C6.10487 11.6993 6.25536 11.6658 6.37833 11.5818L7.8735 10.0758L9.36867 11.5818C9.49674 11.6458 9.64162 11.6678 9.78284 11.6447C9.92407 11.6216 10.0545 11.5546 10.1557 11.4531C10.2568 11.3516 10.3237 11.2208 10.3467 11.0791C10.3697 10.9374 10.3477 10.792 10.2839 10.6636L8.77889 9.16357L10.28 7.66358C10.3638 7.54021 10.3971 7.38923 10.3731 7.24189C10.349 7.09455 10.2694 6.96213 10.1508 6.87202C10.061 6.75298 9.92896 6.67313 9.78209 6.64901C9.63523 6.62488 9.48474 6.65832 9.36176 6.74241L7.87054 8.25229L6.37538 6.74537C6.2524 6.66129 6.10191 6.62785 5.95505 6.65197C5.80818 6.6761 5.67619 6.75595 5.58637 6.87499C5.46771 6.9651 5.38812 7.09752 5.36407 7.24486C5.34002 7.3922 5.37335 7.54317 5.45717 7.66654L6.9622 9.16357L5.46013 10.6636C5.37631 10.7869 5.34298 10.9379 5.36703 11.0852C5.39108 11.2326 5.47067 11.365 5.58933 11.4551V11.4521Z'
      fill='#657991'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M13.6699 0.888916H2.32889C2.12382 0.888916 1.92715 0.970644 1.78214 1.11612C1.63713 1.2616 1.55566 1.4589 1.55566 1.66464V3.9918C1.55566 4.19754 1.63713 4.39484 1.78214 4.54032C1.92715 4.6858 2.12382 4.76752 2.32889 4.76752H2.8447V13.1718C2.8447 13.6862 3.04837 14.1794 3.41089 14.5431C3.77341 14.9068 4.26509 15.1111 4.77777 15.1111H11.221C11.7337 15.1111 12.2254 14.9068 12.5879 14.5431C12.9504 14.1794 13.1541 13.6862 13.1541 13.1718V4.76752H13.6699C13.8749 4.76752 14.0716 4.6858 14.2166 4.54032C14.3616 4.39484 14.4431 4.19754 14.4431 3.9918V1.66464C14.4431 1.4589 14.3616 1.2616 14.2166 1.11612C14.0716 0.970644 13.8749 0.888916 13.6699 0.888916ZM11.866 13.1718C11.866 13.3432 11.7982 13.5075 11.6774 13.6287C11.5566 13.7499 11.3928 13.8179 11.222 13.8179H4.77777C4.60696 13.8179 4.44315 13.7499 4.32237 13.6287C4.2016 13.5075 4.13374 13.3432 4.13374 13.1718V4.76752H11.866V13.1718ZM13.155 3.47531H2.8447V2.18212H13.1541L13.155 3.47531Z'
      fill='#657991'
      fillRule='evenodd'
    />
  </svg>
);

export const AssetItem = ({
  data,
  onClick,
  type = 'horizontal',
  onRemove,
  onEdit,
}) => {
  const [errorImage, setErrorImage] = useState(false);
  const [showPopupRemove, setShowPopupRemove] = useState(false);
  const targetPortal = document.getElementById('bvt-portal');

  const styleHighlight = { userSelect: 'text' };
  const internalOnClick = () => {
    onClick?.(data);
  };

  return (
    <div className='AsmanPertaminaAssetsItems'>
      {type === 'horizontal' && (
        <div className='top-container'>
          <div className={`tag ${data?.is_preanalytic ? 'exist' : 'new'}`}>
            <span>{data?.is_preanalytic ? 'Existing Asset' : 'New Asset'}</span>
          </div>
          <div className='action-button'>
            <Button className='btn' icon={<PencilIcon />} onClick={onEdit} />
            <Button
              className='btn'
              icon={<TrashIcon />}
              onClick={() => setShowPopupRemove(true)}
            />
          </div>
        </div>
      )}
      <div
        className={
          type === 'horizontal' ? 'container-horizontal' : 'container-vertical'
        }
        onClick={internalOnClick}
      >
        <div className='left-content'>
          <div className='top-inner-content'>
            <span className='title' style={styleHighlight}>
              SPBU {data.name ?? 'Assets Name'}
            </span>
            <span
              className='coordinate'
              style={styleHighlight}
            >{`${data.coordinate.lat}, ${data.coordinate.lng}`}</span>
            <span className='address'>{data.address || '-'}</span>
          </div>
          <div className='bottom-inner-content'>
            {data.administrative.map((item, key) => {
              return (
                <div className='tag' key={item + key}>
                  <span className='text'>{item}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className='right-content'>
          <LazyLoadImage
            alt='pertamina'
            className='img'
            effect='blur'
            onErrorCapture={(e) => {
              setErrorImage(true);
              e.target.src = NoPhoto;
            }}
            placeholderSrc={NoPhoto}
            src={data?.image_url || NoPhoto}
            style={{
              objectFit: data?.image_url && !errorImage ? 'cover' : 'fill',
            }}
            wrapperProps={{
              style: { width: '100%' },
            }}
          />
        </div>
      </div>
      {ReactDOM.createPortal(
        <Popup
          body={{
            icon: 'confirmremove',
            desc: (
              <>
                <h2>
                  Are you sure you want to
                  <br /> remove this Marker?
                </h2>
                <p>This process cannot be undone</p>
              </>
            ),
          }}
          cancelButton={true}
          cancelText='Cancel'
          footerButton={{
            leftClass: 'red',
            rightClass: 'red',
          }}
          handler={{
            onHide: () => {
              setShowPopupRemove(false);
            },
            onSubmit: () => {
              setShowPopupRemove(false);
              onRemove?.(data.id);
            },
          }}
          header={false}
          show={showPopupRemove}
          submitText='Remove'
        />,
        targetPortal
      )}
    </div>
  );
};

AssetItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    coordinate: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }).isRequired,
    administrative: PropTypes.arrayOf(PropTypes.string).isRequired,
    image_url: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};
