import Resource from '../../../api/resource';

export class PertaminaService extends Resource {
  constructor() {
    super('', '/api', 'api-asman-telkom', null);
  }

  /**
   * @param {Object} param
   * @param {string} param.name
   * @param {number} param.id_province
   * @param {number} param.id_city
   * @param {number} param.id_district
   * @param {number} param.id_village
   * @returns {Promise<Object>}
   */
  getListAsset(param) {
    const forgeBody = {
      name: param?.name ? param?.name : null,
      id_province: param?.id_province ? param?.id_province : null,
      id_city: param?.id_city ? param?.id_city : null,
      id_district: param?.id_district ? param?.id_district : null,
      id_village: param?.id_village ? param?.id_village : null,
    };
    this.setAdditionUri('assets');
    return this.list(forgeBody);
  }

  /**
   * @param {Object} param
   * @param {string} param.site_id
   * @returns {Promise<Object>}
   */
  getDetailAsset(param) {
    this.setAdditionUri('asset/detail');
    return this.get(null, param);
  }

  /**
   * @param {Object} param
   * @param {string} param.name
   * @param {number} param.id_province
   * @param {number} param.id_city
   * @param {number} param.id_district
   * @param {number} param.id_village
   * @param {function} cb
   * @returns {Promise<Object>}
   */
  downloadAssetList(param, cb) {
    const forgeBody = {
      name: param?.name ? param?.name : null,
      id_province: param?.id_province ? param?.id_province : null,
      id_city: param?.id_city ? param?.id_city : null,
      id_district: param?.id_district ? param?.id_district : null,
      id_village: param?.id_village ? param?.id_village : null,
    };
    this.setAdditionUri('download');
    return this.stream(forgeBody, cb);
  }

  /**
   * @param {string} id
   */
  deleteAsset(id) {
    this.setAdditionUri('onthefly/delete');
    return this.delete(id);
  }
  /**
   *
   * @param {object} param
   * @param {string} param.site_id
   * @param {string} param.name
   * @param {string} param.longitude
   * @param {string} param.latitude
   * @param {string} param.address
   * @param {string} param.image_base64
   */
  createUpdateAsset(param) {
    this.setAdditionUri('onthefly');
    return this.create(param);
  }
  /**
   * @param {string} id
   */
  getRukoList(id) {
    const forgeParam = {
      area_ruko_id: id,
    };
    this.setAdditionUri('ruko');
    return this.get(null, forgeParam);
  }
  /**
   * @param {string} id
   */
  getTenantList(id) {
    const forgeParam = {
      area_tenant_id: id,
    };
    this.setAdditionUri('tenant');
    return this.get(null, forgeParam);
  }
  /**
   * @param {string} id
   */
  getPOIList(id) {
    const forgeParam = {
      area_poi_id: id,
    };
    this.setAdditionUri('poi');
    return this.get(null, forgeParam);
  }
  getStatusAsset() {
    this.setAdditionUri('assets');
    return this.list({ limit: 20 });
  }
}
